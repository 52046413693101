import { FC } from 'react';
import { Box } from '@mantine/core';

import { DictionarySelect } from '@/ui/components/DictionarySelect';
import { DndListItem, DndListItemProps } from '@/ui/components/DndListItem';

import { swapFormFields } from '../CubeContainer.helpers';

import { PERSONAL_FIELDS, type } from './PersonalFormBlock.constants';
import { PersonalFormBlockProps } from './PersonalFormBlock.types';

export const PersonalFormBlock: FC<PersonalFormBlockProps> = ({ form, ...rest }) => {
  const move: DndListItemProps['move'] = (dragIndex, hoverIndex) =>
    swapFormFields(PERSONAL_FIELDS, dragIndex, hoverIndex, form.setValues);

  return (
    <Box>
      {PERSONAL_FIELDS.map((field, index) => (
        <DndListItem key={field} {...{ type, index, move }}>
          <DictionarySelect
            clearable
            searchable
            dictionary='personalaccount'
            placeholder={`Раздел Личного кабинета ${index + 1}`}
            {...form.getInputProps(field)}
            {...rest}
          />
        </DndListItem>
      ))}
    </Box>
  );
};
