import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Group, Select, Sx, Text, TextInput } from '@mantine/core';
import { DateRangePicker, DateRangePickerValue } from '@mantine/dates';
import { RotateClockwise } from 'tabler-icons-react';

import { formatDate, PAGE_ELEMENTS } from '@/constants/common';

import { MultiSelect, TOption } from '@/ui/components/MultiSelect';
import { ReviewAndQuestionsFilterProps } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';

import {
  replayOptions,
  reviewsProductRating,
  reviewStatusOptions,
} from './ReviewAndQuestionsFilter.consts';

import {
  inputStyles,
  itemsPerPageBox,
} from '@/pages/Banners/BannersListing/BannerListing.constants';

const sx: Sx = {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
};

export const ReviewAndQuestionsFilter = (props: ReviewAndQuestionsFilterProps) => {
  const {
    isDirty,
    id,
    article,
    rating,
    createDate,
    status,
    hasReply,
    limit,
    actionWithDebounce,
    onReset,
    onChange,
  } = props;

  const params = useLocation();

  const isQuestionPage = params.pathname.includes('questions');

  const onChangeDate = (v: DateRangePickerValue) => {
    if (!v[0] && !v[1]) {
      return actionWithDebounce({ createDate: [null, null] });
    } else if (v[0] && v[1]) {
      return actionWithDebounce({ createDate: v });
    }
  };

  return (
    <Group
      position='left'
      align='flex-end'
      spacing='xs'
      sx={{ rowGap: 24, position: 'relative', zIndex: 2 }}
      grow
    >
      <Box sx={{ ...sx, minWidth: 208 }}>
        <Text size='sm' color='grayDefault'>
          {isQuestionPage ? 'ID вопроса' : 'ID отзыва'}
        </Text>
        <TextInput
          value={id}
          styles={inputStyles}
          onChange={(v) => actionWithDebounce({ id: v.target.value })}
          placeholder={isQuestionPage ? 'Введите ID вопроса' : 'Введите ID отзыва'}
        />
      </Box>
      <Box sx={{ ...sx, minWidth: 190, maxWidth: 190 }}>
        <Text size='sm' color='grayDefault'>
          Статус
        </Text>
        <MultiSelect
          options={reviewStatusOptions}
          label='Статус'
          value={status}
          onChange={(value) => actionWithDebounce({ status: value as TOption[] })}
        />
      </Box>
      <Box sx={{ ...sx, minWidth: 208 }}>
        <Text size='sm' color='grayDefault'>
          Артикул
        </Text>
        <TextInput
          value={article}
          styles={inputStyles}
          onChange={(v) => actionWithDebounce({ article: v.target.value })}
          placeholder='Введите артикул'
        />
      </Box>
      {!isQuestionPage && (
        <Box sx={{ ...sx, minWidth: 190, maxWidth: 190 }}>
          <Text size='sm' color='grayDefault'>
            Оценка
          </Text>
          <MultiSelect
            options={reviewsProductRating}
            label='Оценка'
            value={rating}
            onChange={(value) => actionWithDebounce({ rating: value as TOption[] })}
          />
        </Box>
      )}
      <Box sx={{ ...sx, minWidth: 190, maxWidth: 190 }}>
        <Text size='sm' color='grayDefault'>
          Наличие ответа
        </Text>
        <MultiSelect
          options={replayOptions}
          isMulti={false}
          label='Наличие ответа'
          value={hasReply}
          onChange={(value) => {
            if ((value as TOption)?.value === hasReply?.value) {
              actionWithDebounce({ hasReply: { value: '', label: '' } });
            } else {
              actionWithDebounce({ hasReply: value as TOption });
            }
          }}
        />
      </Box>
      <Box sx={{ ...sx, minWidth: 272, maxWidth: 272 }}>
        <Text size='sm' color='grayDefault'>
          Дата создания
        </Text>
        <DateRangePicker
          allowSingleDateInRange
          dropdownPosition='bottom-start'
          value={createDate}
          onChange={onChangeDate}
          locale='ru'
          placeholder='Выберите период'
          inputFormat={formatDate}
          sx={(mantineTheme) => ({
            input: {
              height: 39,
              borderColor: mantineTheme.colors.greyLink[9],
              '&:hover': { borderColor: mantineTheme.colors.primary[9] },
              '&:active': { borderColor: mantineTheme.colors.primary[9] },
              '::placeholder': {
                color: mantineTheme.colors.greyDefault[9],
                fontSize: 16,
              },
            },
            'button[data-in-range]': {
              color: '#fff !important',
            },
          })}
        />
      </Box>
      <div style={{ display: 'flex', marginLeft: 'auto', justifyContent: 'end' }}>
        {isDirty && (
          <Box sx={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}>
            <Button
              variant='subtle'
              radius='xs'
              size='sm'
              compact
              sx={({ colors }) => ({
                color: colors.primary[9],
                ':hover': { backgroundColor: 'transparent', color: colors.primary[7] },
              })}
              rightIcon={<RotateClockwise size={20} />}
              onClick={onReset}
            >
              Сбросить
            </Button>
          </Box>
        )}
        <Box sx={itemsPerPageBox}>
          <Select
            sx={{ width: '60px', input: { paddingRight: '20px' } }}
            size='sm'
            value={limit || PAGE_ELEMENTS[0].value}
            onChange={(v) => onChange({ limit: v as string })}
            allowDeselect
            data={PAGE_ELEMENTS}
          />
        </Box>
      </div>
    </Group>
  );
};
