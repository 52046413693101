import { FormValidateInput } from '@mantine/form/lib/types';
import {
  BaseCubeItem,
  CubeMenuResponse,
  UpdateCubeMenuItem,
  UpdateCubeMenuRequest,
} from '@prosv/core/types/bff/cube/admin';

import { AUDIOS_FIELDS } from './AudiosFormBlock/AudiosFormBlock.constants';
import { CATEGORIES_FIELDS } from './CategoriesFormBlock/CategoriesFormBlock.constants';
import { PERSONAL_FIELDS } from './PersonalFormBlock/PersonalFormBlock.constants';
import { PROJECTS_FIELDS } from './ProjectsFormBlock/ProjectsFormBlock.constants';
import { SERVICES_FIELDS } from './ServicesFormBlock/ServicesFormBlock.constants';
import { FIELDS_BLOCKS } from './CubeContainer.constants';
import { BlockValues, CubeForm, CubeFormField, CubeFormValues } from './CubeContainer.types';

/** Преобразует данные из API в значения формы (для отдельного блока) */
export const getFormBlockValues = <Values extends BlockValues, Item extends BaseCubeItem>(
  fields: (keyof Values)[],
  data?: Item[],
): Values => {
  const res: Values = {} as Values;

  const items = [...(data ?? [])]
    .sort(({ sortWeight: a }, { sortWeight: b }) => a - b)
    .slice(0, fields.length);

  fields.forEach((field, index) => {
    res[field] = (items[index]?.id ?? '') as any;
  });

  return res;
};

/** Преобразует данные из API в значения формы */
export const getFormValues = (data?: CubeMenuResponse['payload']): CubeFormValues => ({
  ...getFormBlockValues(CATEGORIES_FIELDS, data?.category),
  ...getFormBlockValues(SERVICES_FIELDS, data?.digitalService),
  ...getFormBlockValues(PERSONAL_FIELDS, data?.personal),
  ...getFormBlockValues(AUDIOS_FIELDS, data?.audioApp),
  ...getFormBlockValues(PROJECTS_FIELDS, data?.project),
});

/** Убирает пустые значения(между заполенными) в списке полей `fields` для одного блока */
export const normalizeBlockValues = (
  values: CubeFormValues,
  fields: CubeFormField[],
): CubeFormValues => {
  const res: CubeFormValues = { ...values };

  const items: string[] = [];
  for (const field of fields) {
    const id = res[field];
    if (id) items.push(id);
  }
  for (let i = 0; i < fields.length; i++) {
    res[fields[i]] = items[i] ?? '';
  }

  return res;
};

/** Убирает пустые значения(между заполенными) во всех блоках */
export const normalizeValues = (values: CubeFormValues): CubeFormValues => {
  let res = { ...values };

  FIELDS_BLOCKS.forEach((fields) => {
    res = normalizeBlockValues(res, fields);
  });

  // если ничего не изменилось, то возвращаем изначальный объект, чтобы избежать бесконечной реактивности при использовании
  return JSON.stringify(res) === JSON.stringify(values) ? values : res;
};

/** меняем местами значения полей (для drag-ndrop) */
export const swapFormFields = (
  fields: CubeFormField[],
  srcIndex: number,
  dstIndex: number,
  setValues: CubeForm['setValues'],
) => {
  const srcField = fields[srcIndex];
  const dstField = fields[dstIndex];
  if (!srcField || !dstField) return;

  setValues((values) => ({
    ...values,
    [srcField]: values[dstField] ?? '',
    [dstField]: values[srcField] ?? '',
  }));
};

/** Создаёт правила валидации для отдельного блока */
export const createFormBlockValidate = (
  fields: (keyof CubeFormValues)[],
): FormValidateInput<CubeFormValues> => {
  const res: FormValidateInput<CubeFormValues> = {};

  fields.forEach((field, index) => {
    res[field] = (value: string, values: CubeFormValues) => {
      if (!value) {
        return index ? null : 'Поле обязательно для заполнения';
      }

      if (fields.some((f, i) => values[f] === value && i !== index)) {
        return 'Значение повторяется';
      }

      return null;
    };
  });

  return res;
};

/** Создаёт правила валидации формы */
export const createFormValidate = (): FormValidateInput<CubeFormValues> => {
  let res: FormValidateInput<CubeFormValues> = {};

  FIELDS_BLOCKS.forEach((fields) => {
    res = { ...res, ...createFormBlockValidate(fields) };
  });

  return res;
};

/** Преобразует значения формы по отдельному блоку в данные для запроса в API */
export const getBlockSubmitData = (
  values: CubeFormValues,
  fields: CubeFormField[],
): UpdateCubeMenuItem[] => {
  const res: UpdateCubeMenuItem[] = [];

  let sortWeight = 0;
  for (const field of fields) {
    const id = values[field];
    if (id) {
      res.push({ id, sortWeight });
      sortWeight++;
    }
  }

  return res;
};

/** Преобразует значения формы в данные для запроса в API */
export const getSubmitData = (values: CubeFormValues): UpdateCubeMenuRequest => ({
  category: getBlockSubmitData(values, CATEGORIES_FIELDS),
  digitalService: getBlockSubmitData(values, SERVICES_FIELDS),
  personal: getBlockSubmitData(values, PERSONAL_FIELDS),
  audioApp: getBlockSubmitData(values, AUDIOS_FIELDS),
  project: getBlockSubmitData(values, PROJECTS_FIELDS),
});
