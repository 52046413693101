import { useEffect, useMemo, useState } from 'react';
import { useForm } from '@mantine/form';
import { useDebouncedCallback } from 'use-debounce';

import { notifySaveError, notifySaveSuccess } from '@/utils/notify';

import {
  createFormValidate,
  getFormValues,
  getSubmitData,
  normalizeValues,
} from './CubeContainer.helpers';
import { CubeFormValues } from './CubeContainer.types';

import { useGetCubeQuery, useUpdateCubeMutation } from '@/store/slices/cube';

const validateRules = createFormValidate();

interface UseCubeFormParams {
  /** открыт ли хотя бы один select */
  isAnyOpen?: boolean;
}

export const useCubeForm = ({ isAnyOpen }: UseCubeFormParams) => {
  const [needValidate, setNeedValidate] = useState(false);
  const { data, isLoading } = useGetCubeQuery();
  const [update] = useUpdateCubeMutation();
  const apiValues = useMemo<CubeFormValues>(() => getFormValues(data), [data]);

  const form = useForm<CubeFormValues>({
    initialValues: apiValues,
    validateInputOnChange: true,
    validate: validateRules,
  });

  const { setValues, values, validate } = form;

  useEffect(() => {
    if (needValidate && !isLoading) {
      setNeedValidate(false);
      validate();
    }
  }, [needValidate, isLoading, validate]);

  const debouncedNormalize = useDebouncedCallback((vals: CubeFormValues) => {
    const normalizedVals = normalizeValues(vals);
    if (normalizedVals !== vals) {
      setValues(normalizedVals);
      setNeedValidate(true);
    }
  }, 100);

  useEffect(() => {
    if (!isAnyOpen) {
      debouncedNormalize(values);
    }
  }, [debouncedNormalize, isAnyOpen, values]);

  useEffect(() => {
    setValues(apiValues);
  }, [apiValues, setValues]);

  const onSubmit = form.onSubmit((vals) => {
    update(getSubmitData(vals)).unwrap().then(notifySaveSuccess).catch(notifySaveError);
  });

  return { form, onSubmit, isLoading };
};
