import React, { FC, useEffect, useRef } from 'react';
import { Box, Input, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { SnippetValues } from '@prosv/core/types/snippets';
import { UmkSystem as UmkSystemSnippetType } from '@prosv/core/types/snippets/umkSystem';
import { Editor } from '@tiptap/react';
import { Trash } from 'tabler-icons-react';

import { S3_STORAGE_LINK } from '@/constants/common';

import { FileLoader } from '@/ui/components/FileLoader';
import { FileLoaderRef } from '@/ui/components/FileLoader';
import { IFileLoaderOnChangeParams } from '@/ui/components/FileLoader/FileLoader.types';
import { CustomLabel } from '@/ui/containers/BannerCreationContainer/components/CustomLabel';
import { getImageData } from '@/ui/containers/ContentEditor/SelectSnippetModalWrapper';
import { TextEditor } from '@/ui/containers/ContentEditor/snippets/TextSnippetModal/TextEditor';
import { UmkSystemSnippetFormFields } from '@/ui/containers/ContentEditor/snippets/UmkSystemSnippet/UmkSystemSnippet.constants';
import { ERROR_MESSAGE_STYLE } from '@/ui/containers/PromotionCreation/components/PromotionForm/PromotionForm.constants';
interface Props {
  index: number;
  form: UseFormReturnType<SnippetValues<UmkSystemSnippetType>>;
  handleRemoveBlock: (index: number) => void;
  sessionID: string;
}

export const UmkSystemBlock: FC<Props> = ({ index, form, sessionID, handleRemoveBlock }) => {
  const editorRef = useRef<Editor | undefined | null>();
  const fileLoaderRef = useRef<FileLoaderRef | null>(null);

  const imgData = getImageData({
    id: form.values.blocks?.[index].image?.id,
    name: form.values.blocks?.[index].image?.name,
    path: form.values.blocks?.[index].image?.path.replace(S3_STORAGE_LINK as string, ''),
  });

  const blockValue = form.values.blocks[index];

  const handleChange = ({ id, path, name }: Omit<IFileLoaderOnChangeParams, 'size' | 'type'>) => {
    if (form.values.blocks?.[index]) {
      form.values.blocks[index] = {
        ...form.values.blocks[index],
        image: {
          id: id,
          path,
          name,
        },
      };
    }
  };

  const handleRemove = () => {
    if (form.values.blocks?.[index]) {
      form.values.blocks[index] = {
        ...form.values.blocks[index],
        image: {
          id: '',
          path: '',
          name: '',
        },
      };
    }
  };

  useEffect(() => {
    if (editorRef.current?.getHTML() !== form.values.blocks[index].text) {
      editorRef.current?.commands.setContent(form.values.blocks[index].text);
    }
    fileLoaderRef.current?.clearFiles();
    fileLoaderRef.current?.setNewFile(imgData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.blocks, index, blockValue]);

  return (
    <Box
      sx={{
        padding: '20px',
        width: '100%',
        gridColumn: 'span 12',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: 10,
      }}
    >
      {index !== 0 && (
        <Trash
          size={20}
          color='gray'
          onClick={() => handleRemoveBlock(index)}
          style={{ cursor: 'pointer', position: 'absolute', top: '16px', right: '16px' }}
        />
      )}
      <Title sx={{ margin: 0, gridColumn: 'span 12' }}>Блок {index + 1}</Title>

      <Input.Wrapper
        label='Изображение'
        sx={{ gridColumn: 'span 12' }}
        {...form.getInputProps(`blocks.${index}.image.id`)}
      >
        <FileLoader
          sessionID={sessionID}
          onRemove={handleRemove}
          onChange={handleChange}
          savedFormFiles={imgData}
          placeholder='Перетащите или добавьте изображение.'
          ref={fileLoaderRef}
        />
      </Input.Wrapper>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gridColumn: 'span 12',
          minHeight: 200,
          position: 'relative',
        }}
      >
        <CustomLabel title='Текст' isRequire numSpanInGrid='8' />
        <TextEditor
          placeholder='Введите текст'
          defaultTextValue={
            form.getInputProps(`blocks.${index}.${UmkSystemSnippetFormFields.TEXT}`).value
          }
          editorRef={editorRef}
          changeFormValue={(value) => {
            form.setFieldValue(`blocks.${index}.${UmkSystemSnippetFormFields.TEXT}`, value);
          }}
        />
        {form.getInputProps(`blocks.${index}.${UmkSystemSnippetFormFields.TEXT}`).error && (
          <div style={ERROR_MESSAGE_STYLE}>
            {form.getInputProps(`blocks.${index}.${UmkSystemSnippetFormFields.TEXT}`).error}
          </div>
        )}
      </Box>
    </Box>
  );
};
