import { CSSProperties } from 'react';

import { GeneralInformationCardTypes } from '@/ui/components/GeneralInformationCard/GeneralInformationCard.types';

export const variants: Record<
  GeneralInformationCardTypes['cardType'],
  { editDate: string; author: string }
> = {
  project: {
    editDate: 'Последнее редактирование:',
    author: 'Создатель:',
  },
  question: {
    editDate: 'Последнее редактирование:',
    author: 'Автор вопроса:',
  },
  support: {
    editDate: 'Дата изменения:',
    author: 'Автор:',
  },
  review: {
    editDate: 'Последнее редактирование:',
    author: 'Автор отзыва:',
  },
};

export const presentProductStyles = {
  paper: {
    border: '1px solid #E8ECF0',
    width: 352,
    flexShrink: 0,
    background: '#F6F8F8',
    height: 'fit-content',
  },
  imgWrapper: {
    maxWidth: 77,
    maxHeight: 96,
    width: '100%',
    borderRadius: 4,
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #E8ECF0',
  },
  uuid: {
    textAlign: 'end',
    marginLeft: 'auto',
    color: '#66788A',
    marginTop: 4,
  },
  emptyImage: {
    background: '#E8ECF0',
    width: 77,
    height: 96,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  } as CSSProperties,
};
