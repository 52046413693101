import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import notify from '@/utils/notify';

import { StatusTypes } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';
import { PageType } from '@/ui/components/ReviewAndQuestionsForm/ReviewAndQuestionsForm.types';
import { ReviewType } from '@/ui/containers/Reviews/types';

import { STATUS_LABELS } from './QuestionsDetail.constants';

import { QuestionAnswer } from '@/store/slices/questions/questions.types';
import {
  useGetSingleQuestionQuery,
  useLazyPatchQuestionAnswerQuery,
  useLazySendQuestionAnswerQuery,
  useLazySetQuestionStatusQuery,
} from '@/store/slices/questions/questionsSlice';
import { useReviewDetailStore } from '@/store/slices/reviews/reviewsSlice';

interface Params {
  lastAnswer?: QuestionAnswer;
  isHaveAnswer: boolean;
  id: string;
}

const useQuestionsConfig = ({ lastAnswer, isHaveAnswer, id }: Params) => {
  const [answer, setAnswer, answerAttachments, sessionId, setAnswerAttachments] =
    useReviewDetailStore((state) => [
      state.params.answer,
      state.setAnswer,
      state.params.attachments,
      state.params.sessionId,
      state.setAttachments,
    ]);

  const navigate = useNavigate();
  const [setStatusRequest, response] = useLazySetQuestionStatusQuery();
  const [sendAnswer, answerResponse] = useLazySendQuestionAnswerQuery();
  const [patchAnswer, patchAnswerResponse] = useLazyPatchQuestionAnswerQuery();

  const isLoading = response.isLoading || patchAnswerResponse.isLoading || answerResponse.isLoading;

  const handleSetStatus = (status: StatusTypes) => {
    setStatusRequest({ id, status }).then((res) => {
      if (res.isSuccess) {
        notify({
          message: 'Статус успешно изменён: ' + STATUS_LABELS[status],
          type: 'success',
        });
        navigate('/questions');
      } else {
        notify({ message: 'Статус не удалось изменить', type: 'error' });
      }
    });
  };

  const handlePatchAnswer = (status: StatusTypes) => {
    const attachmentIds: string[] = [];
    const attachmentNewIds: string[] = [];

    answerAttachments?.forEach((attachmentId) => {
      const isSaved = lastAnswer?.attachments?.find((item) => item.id === attachmentId);
      (isSaved ? attachmentIds : attachmentNewIds).push(attachmentId);
    });

    patchAnswer({
      answer,
      answerId: lastAnswer?.id || '',
      attachments:
        answerAttachments && sessionId ? { sessionId, attachmentIds, attachmentNewIds } : undefined,
    }).then((res) => {
      if (res.isSuccess) {
        setStatusRequest({ id, status }).then((statusRes) => {
          if (statusRes.isSuccess) {
            navigate('/questions');
            notify({ message: 'Ответ на вопрос успешно обновлен ', type: 'success' });
          } else {
            notify({
              message: `Ответ на вопрос успешно обновлен, но статус не изменился на "${STATUS_LABELS[status]}"`,
              type: 'error',
            });
          }
        });
      } else if (res.isError) {
        if (res.originalArgs.answer === '') {
          notify({
            message: `Ответ на вопрос не удалось сохранить. Текст ответа не может быть пустым`,
            type: 'error',
          });
        } else {
          notify({ message: 'Ответ на вопрос не удалось сохранить', type: 'error' });
        }
      }
    });
  };

  const handleSetAnswer = (status: StatusTypes) => {
    sendAnswer({
      answer,
      questionId: id,
      attachments:
        answerAttachments && sessionId
          ? { sessionId, attachmentIds: [], attachmentNewIds: answerAttachments }
          : undefined,
    }).then((res) => {
      if (res.isSuccess) {
        setStatusRequest({ id, status });
        navigate('/questions');
      } else {
        const isNoAnswer = res.originalArgs?.answer === '';
        notify({
          message: `Ответ на вопрос не удалось сохранить${
            isNoAnswer ? '. Текст ответа не может быть пустым' : ''
          }`,
          type: 'error',
        });
      }
    });
  };

  const onSubmitQuestion = (status: StatusTypes) => {
    if (lastAnswer && lastAnswer.answer === answer) {
      const a1 = lastAnswer.attachments?.map(({ id }) => id) ?? [];
      const a2 = answerAttachments ?? [];
      const isAttachmentsEqual = a1.every((v) => a2.includes(v)) && a2.every((v) => a1.includes(v));

      if (isAttachmentsEqual) return handleSetStatus(status);
    }

    if (isHaveAnswer) {
      handlePatchAnswer(status);
    } else {
      handleSetAnswer(status);
    }
  };
  return { onSubmitQuestion, isLoading, setAnswer, setAnswerAttachments, id };
};

export const useQuestionsDetails = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const params = useParams();
  const id = params?.id || '';
  const { data, isLoading: isAnswerLoading } = useGetSingleQuestionQuery(id);
  const payload = data?.payload;
  const lastAnswer = data?.payload.answers?.[data?.payload.answers.length - 1];
  const isHaveAnswer = (data?.payload?.answers?.length || 0) > 0;

  const { onSubmitQuestion, isLoading, setAnswer, setAnswerAttachments } = useQuestionsConfig({
    isHaveAnswer,
    lastAnswer,
    id,
  });

  const newData: Partial<ReviewType> = {
    id: payload?.id || '',
    status: payload?.status || 'new',
    author: { ...(payload?.createdBy || {}) },
    type: 'product',
    reviewChange: {
      updateAt: payload?.answers?.[0]?.createdAt?.value || payload?.answers?.[0]?.updateAt?.value,
      user: payload?.answers?.[0]?.createdBy || payload?.answers?.[0]?.updatedBy,
    },
    entity: payload?.entity,
    rating: 0,
    text: payload?.question,
    reply: undefined,
    attachments: [],
    createDate: payload?.createdAt.value,
  };

  useEffect(() => {
    if (lastAnswer) {
      setAnswer(lastAnswer.answer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    return () => {
      setAnswer('');
      setAnswerAttachments([], '');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHideQuestion = () => {
    setIsOpenModal(true);
  };

  const values = {
    status: data?.payload.status as StatusTypes,
    type: 'product' as ReviewType['type'],
    reviewText: data?.payload.question || '',
    answerAttachments: lastAnswer?.attachments,
    pageType: 'question' as PageType,
  };

  const questionsBreadcrumbs = [
    {
      name: 'Вопросы',
      url: '/questions',
    },
    {
      name: `ID: ${params?.id || ''}`,
    },
  ];

  return {
    questionsBreadcrumbs,
    isLoading,
    id,
    values,
    onHideQuestion,
    onSubmitQuestion,
    data: newData,
    isAnswerLoading,
    isOpenModal,
    setIsOpenModal,
    status: values.status,
  };
};
