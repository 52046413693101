import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { create } from 'zustand';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';
import notify from '@/utils/notify';

import { AsyncJobStatusResponse } from '@/modules/asyncJob';

import { reviewAndQuestionInitialFilter } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.consts';
import { ReviewAndQuestionsQueryParams } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';
import { ReviewsProductsResponse } from '@/ui/containers/Reviews/listing/tabs/ProductsTab/ReviewProductsTab.types';
import { ReviewResponseType } from '@/ui/containers/Reviews/types';

import { formatReviewsRequestData } from './reviewsSlice.helpers';

import { IRootReducer } from '@/store';
import { createTableFilterSlice } from '@/store/slices/banners/bannersSlice';
import {
  PatchReviewAnswerParams,
  PatchReviewStatusParams,
  ResponseReviewProductInfo,
} from '@/store/slices/reviews/reviewsSlice.types';

export const reviewsProductsFilterSlice = createTableFilterSlice<ReviewAndQuestionsQueryParams>({
  name: 'reviewsProductsFilter',
  initialState: reviewAndQuestionInitialFilter,
});
export const reviewsProductsFilterActions = reviewsProductsFilterSlice.actions;

export const reviewsApi = createApi({
  reducerPath: 'api/reviewsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],

  endpoints: (build) => ({
    getReviewsProductsList: build.query<ReviewsProductsResponse, ReviewAndQuestionsQueryParams>({
      query: (params) => {
        return {
          method: 'get',
          url: getUrlWithSearchParams(URLS.review.getList, formatReviewsRequestData(params), {
            arrayFormat: 'index',
            skipNull: true,
          }),
        };
      },
      keepUnusedDataFor: 0,
    }),
    exportReviews: build.query<AsyncJobStatusResponse['payload'], ReviewAndQuestionsQueryParams>({
      query: (params) => {
        return {
          method: 'get',
          url: getUrlWithSearchParams(URLS.review.export, formatReviewsRequestData(params), {
            arrayFormat: 'index',
            skipNull: true,
          }),
        };
      },
      transformResponse: ({ payload }: AsyncJobStatusResponse) => payload,
      keepUnusedDataFor: 0,
    }),
    getSingleProduct: build.query<ResponseReviewProductInfo, string>({
      query: (id) => ({
        method: 'get',
        url: URLS.catalog.getProduct(id),
      }),
    }),
    getSingleReview: build.query<ReviewResponseType, string>({
      query: (id) => ({
        method: 'get',
        url: getUrlWithSearchParams(URLS.review.getDetail(id), {}),
      }),
      keepUnusedDataFor: 0,
    }),
    setStatusReview: build.query<ReviewResponseType, PatchReviewStatusParams>({
      query: ({ id, status }) => ({
        url: URLS.review.patchStatus(id),
        data: { status },
        method: 'patch',
      }),
      keepUnusedDataFor: 0,
      onQueryStarted: async ({ onSuccessPublished, status }, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          onSuccessPublished();
        } catch (e) {
          const getText = () => {
            if (status === 'hidden') {
              return 'Отзыв не удалось скрыть';
            } else {
              return 'Отзыв не удалось опубликовать';
            }
          };
          notify({
            message: getText(),
            type: 'error',
          });
        }
      },
    }),
    setReplyReview: build.query<ReviewResponseType, PatchReviewAnswerParams>({
      query: ({ id, replyText, replyAttachments }) => ({
        url: URLS.review.patchDetail(id),
        data: { replyText, replyAttachments },
        method: 'patch',
      }),
      keepUnusedDataFor: 0,
      onQueryStarted: async ({ onSuccessPublished, onPublishedReview }, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          if (data.payload.status === 'published') {
            onSuccessPublished?.();
          } else {
            onPublishedReview?.();
          }
        } catch (e) {
          notify({
            message: `Отзыв не удалось сохранить`,
            type: 'error',
          });
        }
      },
    }),
  }),
});

export const {
  useLazySetReplyReviewQuery,
  useLazyGetReviewsProductsListQuery,
  useGetSingleProductQuery,
  useGetSingleReviewQuery,
  useLazySetStatusReviewQuery,
  useLazyExportReviewsQuery,
} = reviewsApi;

const reviewsProductsFilter = (state: IRootReducer) => state.reviewsProductsFilter;

export const reviewsProductsFilterSelector = createSelector(reviewsProductsFilter, (params) => {
  return params;
});

export const isDirtyReviewsProductsFilterSelector = createSelector(
  reviewsProductsFilter,
  ({ status, rating, createDate, hasReply, sortDirection, sortField, id, article }) => {
    const initFilterJSON = JSON.stringify({
      status: reviewAndQuestionInitialFilter.status,
      rating: reviewAndQuestionInitialFilter.rating,
      createDate: reviewAndQuestionInitialFilter.createDate,
      hasReply: reviewAndQuestionInitialFilter.hasReply,
      sortDirection: reviewAndQuestionInitialFilter.sortDirection,
      sortField: reviewAndQuestionInitialFilter.sortField,
      id: reviewAndQuestionInitialFilter.id,
      article: reviewAndQuestionInitialFilter.article,
    });

    const currentFilterJSON = JSON.stringify({
      status,
      rating,
      createDate,
      hasReply,
      sortDirection,
      sortField,
      id,
      article,
    });
    return initFilterJSON !== currentFilterJSON;
  },
);

interface ReviewDetailStore {
  params: { answer: string; attachments?: string[]; sessionId?: string };
  setAnswer: (v: string) => void;
  setAttachments: (attachments: string[], sessionId: string) => void;
}

export const useReviewDetailStore = create<ReviewDetailStore>((set) => ({
  params: { answer: '' },
  setAnswer: (answer: string) =>
    set((state) => ({ ...state, params: { ...state.params, answer } })),
  setAttachments: (attachments, sessionId) =>
    set((state) => ({ ...state, params: { ...state.params, attachments, sessionId } })),
}));
