import { useCallback, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { unstable_useBlocker as useBlocker } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';

import notify from '@/utils/notify';

import { ReviewResponseType } from '@/ui/containers/Reviews/types';

import { useModalsStore } from '@/store/slices/modalsZustandStore/modalsStore';
import {
  useLazySetReplyReviewQuery,
  useLazySetStatusReviewQuery,
  useReviewDetailStore,
} from '@/store/slices/reviews/reviewsSlice';

export const useDetailActions = (data?: ReviewResponseType) => {
  const [replyText, answerAttachments, sessionId] = useReviewDetailStore((state) => [
    state.params.answer,
    state.params.attachments,
    state.params.sessionId,
  ]);

  const id = useParams()?.id || '';

  const [updateReview, { isLoading: updateLoading }] = useLazySetReplyReviewQuery();
  const [setWithdrawReview, withdrawReviewData] = useLazySetStatusReviewQuery();
  const navigate = useNavigate();
  const closeModal = useModalsStore((state) => state.closeModal);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const { proceed, reset } = useBlocker(isOpenModal);

  const onConfirmHide = useCallback(() => {
    proceed?.();
    setIsOpenModal(false);
  }, [proceed]);

  const onCancelHide = useCallback(() => {
    reset?.();
    setIsOpenModal(false);
  }, [reset]);

  const onSuccessPublished = () => {
    closeModal();
    navigate('/reviews');
    notify({
      message: 'Отзыв успешно опубликован',
      type: 'success',
    });
  };

  const onSuccessSave = () => {
    navigate('/reviews');
    notify({
      message: 'Отзыв успешно сохранен',
      type: 'success',
    });
  };

  const onPublishedReview = () =>
    setWithdrawReview({
      id,
      reason: data?.payload.text || '',
      status: 'published',
      onSuccessPublished,
    });

  const patchReview = useCallback(() => {
    const attachmentIds: string[] = [];
    const attachmentNewIds: string[] = [];
    const reply = data?.payload?.reply;

    answerAttachments?.forEach((attachmentId) => {
      const isSaved = data?.payload?.reply?.attachments?.find((item) => item.id === attachmentId);
      (isSaved ? attachmentIds : attachmentNewIds).push(attachmentId);
    });

    const isReviewFormIncludesNewContent =
      !!attachmentNewIds.length ||
      reply?.attachments?.length !== attachmentIds.length ||
      reply?.text !== replyText;

    if (isReviewFormIncludesNewContent) {
      updateReview({
        replyText,
        text: data?.payload.text || '',
        replyAttachments:
          answerAttachments && sessionId
            ? { sessionId, attachmentIds, attachmentNewIds }
            : undefined,
        id,
        onSuccessPublished,
        onPublishedReview,
      });
    } else if (data?.payload.status !== 'published') {
      onPublishedReview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyText, data, id, answerAttachments]);

  const hiddenRequest = () => {
    onConfirmHide();
    if (data?.payload.reply?.text !== replyText) {
      updateReview({
        replyText,
        text: data?.payload.text || '',
        id,
      });
    }

    setWithdrawReview({
      id,
      status: 'hidden',
      onSuccessPublished: () => {
        closeModal();
        navigate('/reviews');
        notify({
          message: `Отзыв успешно скрыт `,
          type: 'success',
        });
      },
    });
  };

  const handleHidden = () => {
    if (data?.payload.status !== 'hidden') {
      setIsOpenModal(true);
    } else {
      updateReview({
        id,
        replyText,
        onPublishedReview: onSuccessSave,
      });
    }
  };

  return {
    patchReview,
    isLoading: updateLoading || withdrawReviewData.isLoading,
    handleHidden,
    isOpenModal,
    hiddenRequest,
    onCancelHide,
  };
};
