import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { CatalogTagsAsyncJobStatusResponse } from '@/modules/asyncJob';
import {
  AsyncJobStatusRequest,
  CreateCatalogTagsRequest,
  CreateCatalogTagsResponse,
} from '@/modules/catalogTags/types';

export const catalogTagsSlice = createApi({
  reducerPath: 'api/catalogTags',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['catalogTags'],
  endpoints: (build) => ({
    getAsyncJobStatus: build.query<
      CatalogTagsAsyncJobStatusResponse['payload'],
      AsyncJobStatusRequest
    >({
      query: ({ id }) => ({ method: 'get', url: URLS.asyncJob.getStatus(id) }),
      transformResponse: ({ payload }: CatalogTagsAsyncJobStatusResponse) => payload,
    }),

    createCatalogTags: build.mutation<
      CreateCatalogTagsResponse['payload'],
      CreateCatalogTagsRequest
    >({
      query: (data) => ({ method: 'post', url: URLS.catalogTags.createCatalogTags(), data }),
      transformResponse: ({ payload }: CreateCatalogTagsResponse) => payload,
    }),
  }),
});

export const { useLazyGetAsyncJobStatusQuery, useCreateCatalogTagsMutation } = catalogTagsSlice;
