import { ErrorCode, FileRejection } from 'react-dropzone';

import notify from '@/utils/notify';

import { TAccept } from '@/ui/components/FileLoader/FileLoader.types';

export const getFileLoaderAcceptType = (accept: TAccept) => {
  switch (accept) {
    case 'images':
      return 'defaultImage';
    case 'docs':
      return 'default';
    case 'catalogTagsFiles':
      return 'catalogTagsFiles';
    case 'audio':
      return 'audio';
  }
};

export const acceptedTypes: Record<TAccept, Record<string, string[]>> = {
  docs: {
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    'application/vnd.ms-excel': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    'application/vnd.ms-powerpoint': [],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': [],
  },
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': [],
    'image/gif': [],
    'image/webp': [],
  },
  catalogTagsFiles: {
    'text/csv': [],
  },
  audio: {
    'audio/mpeg': [],
    'audio/mp3': [],
    'audio/x-mp3': [],
    'audio/x-mpeg': [],
    'audio/x-mpg': [],
  },
};

// обработка ошибок при dnd файлов
export const handleDropRejected = (fileRejections: FileRejection[]) => {
  const fileRejection = fileRejections[0];
  const errorMessages: Record<string, string> = {
    [ErrorCode.FileInvalidType]: 'Файл имеет недопустимый формат.',
    [ErrorCode.FileTooLarge]: 'Файл слишком большой. Допустимый размер 15МБ',
  };

  fileRejection.errors.forEach((err) => {
    const message = errorMessages[err.code];
    if (message) {
      notify({
        message,
        type: 'error',
      });
    }
  });
};

// обработка dnd файлов
export const handleDropAccepted = (
  acceptedFiles: File[],
  accept: TAccept,
  fileName?: string,
  sessionID?: string,
) => {
  const formData = new FormData();

  formData.append('file', acceptedFiles[0]);
  formData.append('name', fileName || acceptedFiles[0].name);
  formData.append('configType', getFileLoaderAcceptType(accept));
  formData.append('tmpConfigType', 'default');
  formData.append('sessionId', sessionID ?? '');

  return { file: acceptedFiles[0], formData };
};
