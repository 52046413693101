import { Sx } from '@mantine/core';

export const root: Sx = {
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  border: 'none',
  boxShadow: 'none',
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  button: {
    width: '200px',
  },
};

export const infoWrapper: Sx = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  p: {
    margin: 0,
  },
  section: {
    display: 'flex',
    gap: '10px',
  },
};
