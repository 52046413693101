import { AsyncJobStatusPayload } from '@/modules/asyncJob';

export const EXPORT_MODAL_TITLE: Record<AsyncJobStatusPayload['status'], string> = {
  done: 'Экспорт завершен',
  pending: 'Ожидание загрузки',
  waiting: 'Ожидание загрузки',
  fail: 'Произошла ошибка',
  running: 'В работе',
};

export const EXPORT_MODAL_DESCRIPTION: Record<AsyncJobStatusPayload['status'], string> = {
  done: 'Файл готов к скачиванию',
  pending: '',
  waiting: '',
  running: '',
  fail: 'Экспорт завершился с ошибкой, попробуйте еще раз',
};
