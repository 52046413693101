import { Box, Button, Paper, Tabs, TabsValue } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

import { FileLoaderForm } from '@/modules/mediaStorage/components/FileLoaderForm';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';

import * as sx from './MediaStorage.styles';

export const MediaStorage = () => {
  const [tabValue, setTab] = useLocalStorage<TabsValue>({ key: 'mediaStorageTab' });
  return (
    <Box sx={sx.root}>
      <PageHeader
        title='Медиахранилище'
        rightButton={
          <Box sx={{ display: 'flex', gap: '10px' }}>
            {tabValue && (
              <Button sx={{ width: '200px' }} type='submit' form={tabValue}>
                Сохранить
              </Button>
            )}
          </Box>
        }
      />
      <Paper radius={8} p={24} shadow='xs' sx={sx.contentWrapper}>
        <Tabs
          color='teal'
          defaultValue='imageLoader'
          sx={{ height: '100%' }}
          onTabChange={setTab}
          defaultChecked
        >
          <Tabs.List sx={{ borderBottom: 'none' }}>
            <Tabs.Tab value='imageLoader' color='primary'>
              Загрузка изображений
            </Tabs.Tab>

            <Tabs.Tab value='fileLoader' color='primary'>
              Загрузка файлов
            </Tabs.Tab>

            <Tabs.Tab value='audioLoader' color='primary'>
              Загрузка аудио
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value='imageLoader' pt='xs'>
            <FileLoaderForm formId='imageLoader' />
          </Tabs.Panel>

          <Tabs.Panel value='fileLoader' pt='xs'>
            <FileLoaderForm formId='fileLoader' />
          </Tabs.Panel>

          <Tabs.Panel value='audioLoader' pt='xs'>
            <FileLoaderForm formId='audioLoader' />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Box>
  );
};
