import { FC } from 'react';
import { Box, Button, Modal, Text, Title } from '@mantine/core';

import { AsyncJobStatus, AsyncJobStatusPayload } from '@/modules/asyncJob';
import {
  EXPORT_MODAL_DESCRIPTION,
  EXPORT_MODAL_TITLE,
} from '@/modules/asyncJob/components/ExportModalWindow/ExportModalWindow.constants';
import * as sx from '@/modules/catalogTags/components/CatalogTagsModal/CatalogTagsModal.styles';

interface Props {
  isOpen: boolean;
  jobData: AsyncJobStatusPayload;
  onClose: () => void;
}

export const ExportModalWindow: FC<Props> = ({ jobData, isOpen, onClose }) => {
  const status: AsyncJobStatus = jobData.status;
  const title = EXPORT_MODAL_TITLE[status];
  const description = EXPORT_MODAL_DESCRIPTION[status];
  const downloadUrl = `${process.env.MEDIA_STORAGE_URL}${jobData.result?.path}`;

  const isSuccessStatus = jobData.status === 'done';

  return (
    <Modal
      centered
      size='sm'
      opened={isOpen}
      onClose={onClose}
      title={
        <Title sx={{ position: 'absolute', top: '20px', left: '20%' }} align='center' order={3}>
          {title}
        </Title>
      }
      closeOnEscape
    >
      <Box sx={sx.root}>
        {description && <Text align='center'>{description}</Text>}
        <Box sx={sx.btnWrapper}>
          {isSuccessStatus && downloadUrl && (
            <Button sx={sx.btn} component='a' target='_blank' href={downloadUrl} onClick={onClose}>
              Скачать файл
            </Button>
          )}
          {!isSuccessStatus && (
            <Button sx={sx.btn} onClick={onClose}>
              Ок
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
