import { getFormatDateEndDay, getFormatDateStartDay } from '@/utils/getFormatDateForBackend';

import { ReviewAndQuestionsQueryParams } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';

export const formatRequestQuestionsData = (params: ReviewAndQuestionsQueryParams) => {
  return {
    sort: {
      field: params.sortField,
      direction: params.sortDirection,
    },
    type: ['product'],
    article: params.article ? [params.article] : undefined,
    questionId: params.id ? [params.id] : undefined,
    status: (params.status || []).map((el) => el.value),
    page: {
      limit: params.limit,
      offset:
        params.currentPage === 1 ? 0 : Number(params.limit) * (Number(params.currentPage) - 1),
    },
    dateFrom: params.createDate?.[0] ? getFormatDateStartDay(params.createDate?.[0]) : null,
    dateTo: params.createDate?.[1] ? getFormatDateEndDay(params.createDate?.[1]) : null,
    hasAnswer: params.hasReply?.value ? params.hasReply?.value : undefined,
  };
};
