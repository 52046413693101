import { Box, Button, Paper, Tabs } from '@mantine/core';

import notify from '@/utils/notify';

import { useAppSelector } from '@/hooks/useAppSelector';
import { AsyncJobStatusResponse, ExportModalWindow, useAsyncJob } from '@/modules/asyncJob';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { ProductsTab } from '@/ui/containers/Questions/listing/ProductsTab/ProductsTab';

import {
  questionProductsFilterSelector,
  useExportQuestionsMutation,
} from '@/store/slices/questions/questionsSlice';

const SUCCESS_MESSAGE = 'Задача по экспорту вопросов принята в работу';
const ERROR_MESSAGE = 'Возникла ошибка при экспорте вопросов, попробуйте еще раз';

export const Questions = () => {
  const [exportQuestions] = useExportQuestionsMutation();
  const filter = useAppSelector(questionProductsFilterSelector);
  const { setJobId, jobId, getAsyncJobInfo, asyncJobData, handleCloseModal, openModal } =
    useAsyncJob();

  const handleExportQuestions = async () => {
    const exportJob: { data: AsyncJobStatusResponse['payload'] } | { error: unknown } =
      await exportQuestions(filter);

    if ('data' in exportJob) {
      setJobId(exportJob.data.id);
      notify({
        type: 'success',
        message: SUCCESS_MESSAGE,
      });
    }

    if ('error' in exportJob && exportJob.error) {
      notify({
        type: 'error',
        message: ERROR_MESSAGE,
      });
    }
  };

  return (
    <Box sx={{ height: '96vh', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}>
      {asyncJobData && (
        <ExportModalWindow isOpen={openModal} jobData={asyncJobData} onClose={handleCloseModal} />
      )}
      <PageHeader
        title='Вопросы'
        rightButton={
          <Box sx={{ display: 'flex', gap: '10px' }}>
            {jobId && (
              <Button
                sx={{ width: '260px', border: '1px solid #BFCEDB' }}
                onClick={getAsyncJobInfo}
              >
                Узнать статус экспорта
              </Button>
            )}
            <Button
              sx={{ width: '200px', border: '1px solid #BFCEDB' }}
              variant='white'
              onClick={handleExportQuestions}
            >
              Скачать
            </Button>
          </Box>
        }
      />

      <Paper
        radius={8}
        p={24}
        pb={12}
        shadow='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
        }}
      >
        <Tabs sx={{ height: '100%' }} color='teal' defaultValue='products'>
          <Tabs.List sx={{ borderBottom: 'none' }}>
            <Tabs.Tab value='products' pb='md' mb='xl' color='primary'>
              Продукты
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel sx={{ height: '100%' }} value='products' pt='xs'>
            <ProductsTab />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Box>
  );
};
