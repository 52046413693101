import React from 'react';
import { Box, Button, Tabs } from '@mantine/core';

import notify from '@/utils/notify';

import { useAppSelector } from '@/hooks/useAppSelector';
import { ExportModalWindow, useAsyncJob } from '@/modules/asyncJob';

import PageHeader from '@/ui/components/Page/components/PageHeader/PageHeader';
import { ReviewsProductsTab } from '@/ui/containers/Reviews/listing/tabs/ProductsTab/ReviewsProductsTab';

import {
  reviewsProductsFilterSelector,
  useLazyExportReviewsQuery,
} from '@/store/slices/reviews/reviewsSlice';

const SUCCESS_MESSAGE = 'Задача по экспорту отзывов принята в работу';
const ERROR_MESSAGE = 'Возникла ошибка при экспорте отзывов, попробуйте еще раз';

export const Reviews = () => {
  const [exportReviews] = useLazyExportReviewsQuery();
  const filters = useAppSelector(reviewsProductsFilterSelector);
  const { jobId, getAsyncJobInfo, setJobId, asyncJobData, handleCloseModal, openModal } =
    useAsyncJob();

  const handleExportReviews = async () => {
    exportReviews(filters)
      .unwrap()
      .then((res) => {
        setJobId(res.id);
        notify({
          type: 'success',
          message: SUCCESS_MESSAGE,
        });
      })
      .catch(() => {
        notify({
          type: 'error',
          message: ERROR_MESSAGE,
        });
      });
  };

  return (
    <>
      {asyncJobData && (
        <ExportModalWindow isOpen={openModal} jobData={asyncJobData} onClose={handleCloseModal} />
      )}
      <PageHeader
        title='Отзывы'
        rightButton={
          <Box sx={{ display: 'flex', gap: '10px' }}>
            {jobId && (
              <Button
                sx={{ width: '260px', border: '1px solid #BFCEDB' }}
                onClick={getAsyncJobInfo}
              >
                Узнать статус экспорта
              </Button>
            )}
            <Button
              sx={{ width: '200px', border: '1px solid #BFCEDB' }}
              variant='white'
              onClick={handleExportReviews}
            >
              Скачать
            </Button>
          </Box>
        }
      />

      <Tabs
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 24,
          paddingBottom: 12,
          background: 'white',
          borderRadius: 8,
          marginTop: 16,
        }}
        color='teal'
        defaultValue='products'
      >
        <Tabs.List sx={{ borderBottom: 'none' }}>
          <Tabs.Tab value='products' pb='md' mb='xl' color='primary'>
            Продукты
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 196px)',
          }}
          value='products'
          pt='xs'
        >
          <ReviewsProductsTab />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
