export const endOfDay = (date?: Date | null) => {
  if (!date) return null;

  const res = new Date(date);

  res.setHours(23);
  res.setMinutes(59);
  res.setSeconds(59);

  return res;
};
