export const BUTTON_TEXT = {
  new: {
    primary: 'Не публиковать на сайте',
    secondary: 'Опубликовать на сайте',
  },
  published: {
    primary: 'Снять с публикации',
    secondary: 'Сохранить изменения',
  },
  hidden: {
    primary: 'Сохранить и не публиковать',
    secondary: 'Опубликовать на сайте',
  },
  delete: null,
  removal: null,
};

export const REMOVE_REVIEW_MODAL_TEXT = {
  title: 'Отклонить отзыв?',
  text: 'Отзыв будет отклонён и не будет отображаться на сайте. В личном кабинете пользователя отзыв останется в статусе “Отклонён”',
};
