import { FC } from 'react';
import { Box } from '@mantine/core';

import { DndListItem, DndListItemProps } from '@/ui/components/DndListItem';
import { ProjectSelect } from '@/ui/components/ProjectSelect';

import { swapFormFields } from '../CubeContainer.helpers';

import { PROJECTS_FIELDS, type } from './ProjectsFormBlock.constants';
import { ProjectsFormBlockProps } from './ProjectsFormBlock.types';

export const ProjectsFormBlock: FC<ProjectsFormBlockProps> = ({ form, ...rest }) => {
  const move: DndListItemProps['move'] = (dragIndex, hoverIndex) =>
    swapFormFields(PROJECTS_FIELDS, dragIndex, hoverIndex, form.setValues);

  return (
    <Box>
      {PROJECTS_FIELDS.map((field, index) => (
        <DndListItem key={field} {...{ type, index, move }}>
          <ProjectSelect
            clearable
            searchable
            placeholder={`Проект ${index + 1}`}
            {...form.getInputProps(field)}
            {...rest}
          />
        </DndListItem>
      ))}
    </Box>
  );
};
