import { useState } from 'react';

import { useLazyGetAsyncJobStatusQuery } from '@/modules/asyncJob/asyncJob.slice';

export const useAsyncJob = () => {
  const [jobId, setJobId] = useState<string>();
  const [getJobStatus, { data: asyncJobData }] = useLazyGetAsyncJobStatusQuery();
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getAsyncJobInfo = async () => {
    if (jobId) {
      await getJobStatus({ id: jobId });
      setOpenModal(true);
    }
  };

  return {
    handleCloseModal,
    setJobId,
    getAsyncJobInfo,
    asyncJobData,
    openModal,
    jobId,
  };
};
