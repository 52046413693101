import { IUploadFileResponse } from '@/types/file';

import { useState } from 'react';

import { URLS } from '@/api/urls';

import notify from '@/utils/notify';

import api from '../../instance';

export const useUploadFile = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    uploadTempFile: async (formData: FormData) => {
      setIsLoading(true);
      try {
        const { data } = await api.post<IUploadFileResponse>(URLS.file.uploadTempFile(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        return data.payload.file ?? null;
      } catch (err) {
        return notify({ message: 'Произошла ошибка при загрузке файла', type: 'error' });
      } finally {
        setIsLoading(false);
      }
    },
  };
};
