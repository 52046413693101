import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { AsyncJobStatusRequest, AsyncJobStatusResponse } from './asyncJob.types';

export const asyncJobSlice = createApi({
  reducerPath: 'api/asyncJob',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['asyncJob'],
  endpoints: (build) => ({
    getAsyncJobStatus: build.query<AsyncJobStatusResponse['payload'], AsyncJobStatusRequest>({
      query: ({ id }) => ({ method: 'get', url: URLS.asyncJob.getStatus(id) }),
      transformResponse: ({ payload }: AsyncJobStatusResponse) => payload,
    }),
  }),
});

export const { useLazyGetAsyncJobStatusQuery } = asyncJobSlice;
