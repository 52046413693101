import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '@/api/instance';
import { URLS } from '@/api/urls';

import { getUrlWithSearchParams } from '@/utils/getUrlWithSearchParams';

import { PAGE_ELEMENTS } from '@/constants/common';
import { AsyncJobStatusResponse } from '@/modules/asyncJob';

import {
  ReviewAndQuestionsQueryParams,
  StatusTypes,
} from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter.types';

import { IRootReducer } from '@/store';
import { createTableFilterSlice } from '@/store/slices/banners/bannersSlice';
import { formatRequestQuestionsData } from '@/store/slices/questions/questions.helpers';
import {
  PatchQuestionAnswerParams,
  PostQuestionAnswerParams,
  QuestionsProductsFilter,
  QuestionsResponse,
  QuestionType,
} from '@/store/slices/questions/questions.types';

export const questionsProductsInitialState: QuestionsProductsFilter = {
  currentPage: 1,
  limit: PAGE_ELEMENTS[0].value,
  createDate: [null, null],
  status: [],
  rating: [],
  article: '',
  id: '',
  hasReply: { label: 'Все', value: '' },
};

export const questionsProductsFilterSlice = createTableFilterSlice<QuestionsProductsFilter>({
  name: 'questionsProductsFilter',
  initialState: questionsProductsInitialState,
});

export const questionsProductsFilterActions = questionsProductsFilterSlice.actions;

export const questionsApi = createApi({
  reducerPath: 'api/questions',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],
  endpoints: (build) => ({
    getQuestionsProductsList: build.query<QuestionsResponse, ReviewAndQuestionsQueryParams>({
      query: (params) => {
        return {
          method: 'post',
          url: getUrlWithSearchParams(URLS.questions.getList, {}),
          data: formatRequestQuestionsData(params),
        };
      },
      keepUnusedDataFor: 0,
    }),
    exportQuestions: build.mutation<
      AsyncJobStatusResponse['payload'],
      ReviewAndQuestionsQueryParams
    >({
      query: (params) => ({
        method: 'post',
        url: getUrlWithSearchParams(URLS.questions.export, {}),
        data: formatRequestQuestionsData(params),
      }),
      transformResponse: ({ payload }: AsyncJobStatusResponse) => payload,
    }),
    getSingleQuestion: build.query<{ payload: QuestionType }, string>({
      query: (id) => {
        return {
          method: 'get',
          url: getUrlWithSearchParams(URLS.questions.getItem(id)),
        };
      },
      keepUnusedDataFor: 0,
    }),
    setQuestionStatus: build.query<void, { id: string; status: StatusTypes }>({
      query: ({ status, id }) => ({
        method: 'patch',
        url: getUrlWithSearchParams(URLS.questions.setStatus),
        data: {
          ids: [id],
          status,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    patchQuestionAnswer: build.query<void, PatchQuestionAnswerParams>({
      query: ({ answerId, answer, attachments }) => ({
        method: 'patch',
        url: getUrlWithSearchParams(URLS.questions.patchAnswer(answerId)),
        data: { answer, attachments },
      }),
      keepUnusedDataFor: 0,
    }),
    sendQuestionAnswer: build.query<void, PostQuestionAnswerParams>({
      query: (params) => ({
        method: 'post',
        url: getUrlWithSearchParams(URLS.questions.postAnswer),
        data: params,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLazyGetQuestionsProductsListQuery,
  useExportQuestionsMutation,
  useGetSingleQuestionQuery,
  useLazySetQuestionStatusQuery,
  useLazySendQuestionAnswerQuery,
  useLazyPatchQuestionAnswerQuery,
} = questionsApi;

const questionProductsFilter = (state: IRootReducer) => state.questionsProductsFilter;

export const questionProductsFilterSelector = createSelector(questionProductsFilter, (params) => {
  return params;
});

export const isDirtyQuestionsFilterSelector = createSelector(
  questionProductsFilter,
  ({ status, rating, createDate, hasReply, sortDirection, sortField }) => {
    const initFilterJSON = JSON.stringify({
      status: questionsProductsInitialState.status,
      rating: questionsProductsInitialState.rating,
      createDate: questionsProductsInitialState.createDate,
      hasReply: questionsProductsInitialState.hasReply,
      sortDirection: questionsProductsInitialState.sortDirection,
      sortField: questionsProductsInitialState.sortField,
    });

    const currentFilterJSON = JSON.stringify({
      status,
      rating,
      createDate,
      hasReply,
      sortDirection,
      sortField,
    });
    return initFilterJSON !== currentFilterJSON;
  },
);
