import React, { FC, useEffect } from 'react';

import { QuestionsAndReviewForm } from '@/ui/components/ReviewAndQuestionsForm/ReviewAndQuestionsForm';
import { PageType } from '@/ui/components/ReviewAndQuestionsForm/ReviewAndQuestionsForm.types';
import { ReviewResponseType } from '@/ui/containers/Reviews/types';

import { useReviewDetailStore } from '@/store/slices/reviews/reviewsSlice';

interface Props {
  data: ReviewResponseType;
  isLoading: boolean;
}

export const ReviewForm: FC<Props> = ({ data, isLoading }) => {
  const values = {
    status: data?.payload.status,
    type: data?.payload.type,
    rating: data?.payload.rating,
    reviewText: data?.payload?.text,
    attachments: data?.payload?.attachments || [],
    answerAttachments: data?.payload?.reply?.attachments || [],
    pageType: 'review' as PageType,
  };

  const [setAnswer, setAttachment] = useReviewDetailStore((state) => [
    state.setAnswer,
    state.setAttachments,
    state.params.sessionId,
  ]);

  useEffect(() => {
    if (data?.payload?.reply?.text) {
      setAnswer(data?.payload?.reply?.text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    return () => {
      setAnswer('');
      setAttachment([], '');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <QuestionsAndReviewForm {...values} isLoading={isLoading} />;
};
