import { FC, MouseEventHandler, useMemo } from 'react';
import { Box } from '@mantine/core';

import { IFileWithPreview } from '../FileLoader.types';

import { ReactComponent as RemoveIcon } from '@/assets/icons/remove.svg';

interface IProps {
  file: Pick<IFileWithPreview, 'id' | 'name' | 'preview'>;
  onRemove: (file: string) => void;
  isFullWidth: boolean;
}

export const ValueComponent: FC<IProps> = ({ file, onRemove, isFullWidth }) => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (file.id) {
      onRemove(file.id);
    }
  };

  const isPreviewExist = useMemo(() => {
    const regexp = /^.*\.(jpg|jpeg|png|gif|webp)$/g;
    return regexp.test(file.name);
  }, [file]);

  const format = useMemo(() => {
    const splittedName = file.name.split('.');
    return splittedName[splittedName.length - 1];
  }, [file.name]);

  return (
    <Box
      sx={{
        width: isFullWidth ? '100%' : '280px',
        height: '80px',
        padding: '8px 10px 8px 8px',
        background: '#F6F8F8',
        border: '1px solid #E8ECF0',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {isPreviewExist ? (
        <img
          src={file.preview}
          alt={file.name}
          style={{
            width: '96px',
            height: '64px',
            objectFit: 'contain',
          }}
        />
      ) : (
        <div
          style={{
            width: '96px',
            height: '64px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 700,
            borderRadius: 5,
            border: '1px solid #E8ECF0',
          }}
        >
          {format}
        </div>
      )}

      <p
        style={{
          color: '#121212',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '17px',
          margin: '0 8px',
          maxWidth: '128px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {file.name}
      </p>
      {!!file.id && (
        <button
          type='button'
          onClick={handleClick}
          style={{
            appearance: 'none',
            border: 'none',
            background: 'none',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#BFCEDB',
            cursor: 'pointer',
            width: 20,
            height: 20,
            marginLeft: 'auto',
          }}
        >
          <RemoveIcon />
        </button>
      )}
    </Box>
  );
};
