import { FC, forwardRef } from 'react';

import { useFileLoaderState } from '@/ui/components/FileLoader/FileLoader.hooks';
import { textStyle } from '@/ui/components/FileLoader/FileLoader.styles';
import { ValueComponent } from '@/ui/components/FileLoader/ValueComponent';
import { Loader } from '@/ui/containers/ContentEditor/Loader';

import { FileLoaderRef, IFileLoaderProps, PlaceholderComponentProps } from './FileLoader.types';

import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';

const PlaceholderComponent: FC<PlaceholderComponentProps> = ({ placeholder }) => (
  <>
    <p style={textStyle}>{placeholder ?? 'Перетащите или добавьте изображения'}</p>
    <PlusIcon />
  </>
);

export const FileLoader = forwardRef<FileLoaderRef, IFileLoaderProps>((props, ref) => {
  const { isFullWidth = false } = props;
  const state = useFileLoaderState({ ...props }, ref);

  return (
    <>
      <Loader loading={state.isLoading} />
      <div {...state.getRootProps({ style: state.style })}>
        <input type='hidden' name='file_type' value={state.fileType} />
        <input type='hidden' name='file_size' value={state.fileSize} />
        <input type='hidden' name='file_id' value={state.fileId} />
        <input type='hidden' name='file_temp_id' value={state.fileTempId} />
        <input {...state.getInputProps()} />
        {state.isShowPreview ? (
          state.filesToRender.map((file) => (
            <ValueComponent
              key={file.name}
              isFullWidth={isFullWidth}
              file={file}
              onRemove={state.handleRemove}
            />
          ))
        ) : (
          <PlaceholderComponent />
        )}
      </div>
    </>
  );
});
