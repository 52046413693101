import React, { FC, memo } from 'react';
import { Box, Button } from '@mantine/core';

import ModalConfirm from '@/ui/components/ModalConfirm/ModalConfirm';
import { useDetailActions } from '@/ui/containers/Reviews/ReviewsDetails/ReviewActionButtons/ReviewActionButtons.hooks';
import { ReviewResponseType } from '@/ui/containers/Reviews/types';

import { BUTTON_TEXT, REMOVE_REVIEW_MODAL_TEXT } from './ReviewActionButtons.constants';

interface Props {
  data?: ReviewResponseType;
}

export const ReviewActionButtons: FC<Props> = memo(({ data }) => {
  const { patchReview, isLoading, handleHidden, isOpenModal, hiddenRequest, onCancelHide } =
    useDetailActions(data);

  const canUpdate = data?.payload?.reviewPermissions?.isCanUpdate;
  const status = data?.payload?.status;
  const btnText = !!status ? BUTTON_TEXT[status] : BUTTON_TEXT.new;
  const redText = status === 'new' ? 'red' : '';

  if (!btnText) return null;

  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      <Button
        disabled={isLoading || !canUpdate}
        onClick={handleHidden}
        variant='default'
        style={{ color: redText }}
      >
        {btnText.primary}
      </Button>
      <Button
        disabled={isLoading || !canUpdate}
        onClick={patchReview}
        sx={{ background: '#02C39A' }}
      >
        {btnText.secondary}
      </Button>
      {isOpenModal && (
        <ModalConfirm
          title={REMOVE_REVIEW_MODAL_TEXT.title}
          text={REMOVE_REVIEW_MODAL_TEXT.text}
          opened={isOpenModal}
          onClose={onCancelHide}
          confirmHandler={hiddenRequest}
        />
      )}
    </Box>
  );
});
