import { CSSProperties } from 'react';

import { IGetStylesParams } from '@/ui/components/FileLoader/FileLoader.types';

export const getStyles = ({ accept, reject, hasFiles }: IGetStylesParams): CSSProperties => {
  const getCurrentColor = () => {
    if (accept) return '#706FDD';
    if (reject) return 'red';

    return '#BFCEDB';
  };

  return {
    flex: 1,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: hasFiles ? 'flex-start' : 'center',
    padding: hasFiles ? '17px 18px 16px' : '30px 60px',
    textAlign: hasFiles ? 'initial' : 'center',
    border: '2px dashed',
    borderColor: getCurrentColor(),
    borderRadius: 8,
    backgroundColor: '#fff',
    color: getCurrentColor(),
    transition: 'border-color .3s ease-in-out, color .3s ease-in-out',
  };
};

export const textStyle: CSSProperties = {
  margin: 0,
  marginBottom: 13,
  fontSize: '16px',
  lineHeight: '18px',
};
