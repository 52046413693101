import { Box, Button, Select, Text, TextInput } from '@mantine/core';
import { DateRangePicker, DateRangePickerValue } from '@mantine/dates';
import { IconCalendar, IconSearch } from '@tabler/icons';
import { RotateClockwise } from 'tabler-icons-react';

import { endOfDay } from '@/utils/datetime';

import { formatDate, PAGE_ELEMENTS } from '@/constants/common';

import {
  activeStatus,
  inputStyles,
  option,
} from '@/ui/containers/UmkListContainer/components/UmkListFilters/UmkListFilters.constants';
import { sx } from '@/ui/containers/UmkListContainer/UmkListContainer.constants';

import { ReturnedUmkListConfig } from '@/store/slices/umkList/umkList.types';

export const UmkListFilters = ({
  actionWithDebounce,
  isDirty,
  onChange,
  onReset,
  filters,
  isShowResetFiltersBtn = false,
}: ReturnedUmkListConfig & { isShowResetFiltersBtn?: boolean }) => {
  const { title, isActive, createdAt, updatedAt, limit } = filters;
  const onChangeDate = (v: DateRangePickerValue, key: 'createdAt' | 'updatedAt') => {
    if (!v[0] && !v[1]) {
      return onChange({ [key]: [null, null] });
    } else if (v[0] && v[1]) {
      return onChange({ [key]: [v[0], endOfDay(v[1])] });
    }
  };

  return (
    <>
      <Box
        mb={24}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Box style={{ display: 'flex', gap: '24px', flexWrap: 'wrap' }}>
          <Box sx={sx}>
            <Text size='sm' color='grayDefault'>
              Название
            </Text>
            <TextInput
              value={title}
              icon={<IconSearch size={20} />}
              styles={inputStyles}
              onChange={(e) => actionWithDebounce({ title: e.target.value })}
              placeholder='Введите название'
            />
          </Box>
          <Box sx={sx}>
            <Text size='sm' color='grayDefault'>
              Активность
            </Text>
            <Select
              data={activeStatus}
              clearable
              styles={{ input: { height: 39 } }}
              placeholder='Активность'
              value={isActive?.value || ''}
              onChange={(value) => {
                actionWithDebounce({ isActive: option[value as keyof typeof option] });
              }}
            />
          </Box>
          <Box sx={sx}>
            <Text size='sm' color='grayDefault'>
              Дата создания
            </Text>
            <DateRangePicker
              inputFormat={formatDate}
              locale='ru'
              allowSingleDateInRange
              icon={<IconCalendar size={16} />}
              placeholder='Дата создания'
              value={createdAt}
              onChange={(e) => onChangeDate(e, 'createdAt')}
              sx={(mantineTheme) => ({
                input: {
                  height: 39,
                  borderColor: mantineTheme.colors.greyLink[9],
                  '&:hover': { borderColor: mantineTheme.colors.primary[9] },
                  '&:active': { borderColor: mantineTheme.colors.primary[9] },
                  '::placeholder': {
                    color: mantineTheme.colors.greyDefault[9],
                    fontSize: 16,
                  },
                },
                'button[data-in-range]': {
                  color: '#fff !important',
                },
              })}
            />
          </Box>
          <Box sx={sx}>
            <Text size='sm' color='grayDefault'>
              Дата редактирования
            </Text>
            <DateRangePicker
              inputFormat={formatDate}
              locale='ru'
              allowSingleDateInRange
              icon={<IconCalendar size={16} />}
              placeholder='Дата редактирования'
              value={updatedAt}
              onChange={(e) => onChangeDate(e, 'updatedAt')}
              sx={(mantineTheme) => ({
                input: {
                  height: 39,
                  borderColor: mantineTheme.colors.greyLink[9],
                  '&:hover': { borderColor: mantineTheme.colors.primary[9] },
                  '&:active': { borderColor: mantineTheme.colors.primary[9] },
                  '::placeholder': {
                    color: mantineTheme.colors.greyDefault[9],
                    fontSize: 16,
                  },
                },
                'button[data-in-range]': {
                  color: '#fff !important',
                },
              })}
            />
          </Box>
        </Box>

        {isDirty && isShowResetFiltersBtn && (
          <Box sx={{ display: 'flex', alignItems: 'center', height: 38, maxWidth: 'fit-content' }}>
            <Button
              variant='subtle'
              radius='xs'
              size='sm'
              compact
              sx={({ colors }) => ({
                color: colors.primary[9],
                ':hover': { backgroundColor: 'transparent', color: colors.primary[7] },
              })}
              rightIcon={<RotateClockwise size={20} />}
              onClick={onReset}
            >
              Сбросить
            </Button>
          </Box>
        )}

        <Select
          sx={{ width: '72px', input: { paddingRight: '20px' } }}
          size='sm'
          value={limit || PAGE_ELEMENTS[0].value}
          onChange={(v) => {
            if (v === limit) return;
            actionWithDebounce({ limit: v as string });
          }}
          label=''
          data={PAGE_ELEMENTS}
        />
      </Box>
    </>
  );
};
