import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, yupResolver } from '@mantine/form';
import { UMKItem } from '@prosv/core/types/bff/umk';
import { UmkDetailInfo } from '@prosv/core/types/bff/umk/admin';
import { CreateUmkItemRequest } from '@prosv/core/types/bff/umk/admin/create';
import { useDebouncedCallback } from 'use-debounce';

import { useGetBanners } from '@/ui/containers/BannerCreationContainer/BannerCreationContainer.hooks';
import { getConvertAndFilteredBanners } from '@/ui/containers/PromotionCreation/components/PromotionDetailInfo/PromotionDetailInfo.helper';
import { IBannerSelectorItem } from '@/ui/containers/PromotionCreation/PromotionCreation.types';

import { notifySaveSuccess } from './UmkDetailForm.helpers';

import {
  useCreateUmkPageMutation,
  useSearchUmkMutation,
  useUpdateUmkPageMutation,
} from '@/pages/UmkDetail/UmkDetail.hooks';
import { UmkDetailFormProps } from '@/pages/UmkDetail/UmkDetailForm/UmkDetailForm';
import {
  DEFAULT_BANNERS_LIMIT,
  EUmkFormFields,
  SEARCH_DEBOUNCE_LIMIT,
  SEARCH_QUERY_LIMIT,
} from '@/pages/UmkDetail/UmkDetailForm/UmkDetailForm.constants';
import {
  convertFormDataToRequest,
  getSearchUmkParams,
  getUmkDefaultValue,
  getUmkListOptions,
  notifyError,
  notifyUpdateSuccess,
} from '@/pages/UmkDetail/UmkDetailForm/UmkDetailForm.helpers';
import { umkDetailFormSchema } from '@/pages/UmkDetail/UmkDetailForm/UmkDetailForm.schema';
import { UmkDetailFormTypes } from '@/pages/UmkDetail/UmkDetailForm/UmkDetailForm.types';

export const useUmkDetailFormState = ({
  draftIds,
  umkType,
  umkInfo,
  isNewUmk,
}: UmkDetailFormProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchUmk] = useSearchUmkMutation();
  const [createUmk] = useCreateUmkPageMutation();
  const [updateUmk] = useUpdateUmkPageMutation();

  const { getBanners, isLoading } = useGetBanners(DEFAULT_BANNERS_LIMIT);

  const [createdUmkLink, setCreatedUmkLink] = useState('');
  const [open, setOpen] = useState(false);
  const [banners, setBanners] = useState<IBannerSelectorItem[]>();
  const [umkList, setUmkList] = useState<UMKItem[]>([]);
  const [umkNameFieldOptions, setUmkNameFieldOption] = useState<{ value: string; label: string }[]>(
    [],
  );

  const isShowBannerField = umkType === 'line';

  const form = useForm({
    initialValues: getUmkDefaultValue(umkInfo, umkType),
    validate: yupResolver(umkDetailFormSchema),
  });

  const handleSearchDebounced = useDebouncedCallback(async (query: string) => {
    const searchUmkList = await searchUmk(getSearchUmkParams(umkType, query));

    if ('data' in searchUmkList) {
      setUmkList(searchUmkList.data.items);
      setUmkNameFieldOption(getUmkListOptions(searchUmkList.data.items));
    }
  }, SEARCH_DEBOUNCE_LIMIT);

  const handleSearch = async (query: string) => {
    if (query && query.length >= SEARCH_QUERY_LIMIT) {
      handleSearchDebounced(query);
    }
  };

  useEffect(() => {
    getBanners({ page: ['umk'] }).then((bannersResponse) => {
      if (bannersResponse) {
        setBanners(getConvertAndFilteredBanners(bannersResponse.payload.items));
      }
    });
    handleSearchDebounced(umkType);

    if (!isNewUmk && umkInfo) {
      setUmkNameFieldOption([{ value: umkInfo.umk.id, label: umkInfo.umk.name }]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpen(false);
    form.setFieldValue(EUmkFormFields.UMK_NAME, '');
  };

  const handleCreateResponse = (umkDetailInfo: UmkDetailInfo) => {
    notifySaveSuccess();
    navigate(`/umk/${umkDetailInfo.id}`);
  };

  const handleSubmit = (formValue: UmkDetailFormTypes) => {
    const request = isNewUmk
      ? createUmk(convertFormDataToRequest(formValue, draftIds) as CreateUmkItemRequest)
      : updateUmk({
          data: convertFormDataToRequest(formValue, draftIds, 'update'),
          id: id || '',
        });

    request
      .unwrap()
      .then((res) => (isNewUmk ? handleCreateResponse(res) : notifyUpdateSuccess()))
      .catch(notifyError);
  };

  const handleChangeUmkFieldName = (umkId: string | null) => {
    const umk = umkList.find((item) => item.id === umkId);

    if (umk?.pageId) {
      setCreatedUmkLink(umk?.pageId);
      setOpen(true);
    }

    if (umkId) {
      form.setFieldValue(EUmkFormFields.UMK_NAME, umkId);
    }
  };

  return {
    form,
    banners,
    umkList,
    umkNameFieldOptions,
    open,
    createdUmkLink,
    isShowBannerField,
    isBannerLoading: isLoading,
    handleClose,
    handleSubmit,
    handleChangeUmkFieldName,
    handleSearch,
  };
};
